import React from 'react';
import s from './Header.scss';
import {useControllerProps} from '../../ControllerContext';
import settingsParams from '../../../settingsParams';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import {disableTranslationEscaping} from '../../../disableTranslationEscaping';
import {useLocaleKeys} from '../../../LocaleKeysProvider';

export enum HeaderDataHook {
  root = 'HeaderDataHook.root',
  title = 'HeaderDataHook.title',
  subtitle = 'HeaderDataHook.subtitle',
  orderNumberLine = 'HeaderDataHook.orderNumberLine',
}

export function Header() {
  const {get} = useSettings();
  const localeKeys = useLocaleKeys();
  const {buyerName, isSubscription, orderId} = useControllerProps().thankYouPageStore;

  const getSubscriptionTitle = () => {
    return (
      get(settingsParams.THANK_YOU_PAGE_SUBSCRIPTION_TITLE) ??
      localeKeys.THANK_YOU_PAGE_SUBSCRIPTION_TITLE(disableTranslationEscaping({fullName: buyerName}))
    );
  };

  const getOrderTitle = () => {
    const customTitle = get(settingsParams.THANK_YOU_PAGE_TITLE);
    if (customTitle) {
      return customTitle;
    }

    return buyerName
      ? localeKeys.thankYouPage.title.withName(disableTranslationEscaping({fullName: buyerName}))
      : 'TITLE_NO_BUYER_NAME_NEED_TRANSLATION';
  };

  return (
    <div data-hook={HeaderDataHook.root} className={s.root}>
      <h1 className={s.title}>
        <span data-hook={HeaderDataHook.title}>{isSubscription ? getSubscriptionTitle() : getOrderTitle()}</span>
      </h1>

      <div className={s.subtitle} data-hook={HeaderDataHook.subtitle}>
        <span>
          {isSubscription
            ? get(settingsParams.THANK_YOU_PAGE_SUBSCRIPTION_SUBTITLE)
            : get(settingsParams.THANK_YOU_PAGE_SUBTITLE)}
        </span>
      </div>

      <div className={s.orderNumberLine} data-hook={HeaderDataHook.orderNumberLine}>
        <span>{localeKeys.thankYouPage.subtitle.orderNumber({orderNumber: orderId})}</span>
      </div>
    </div>
  );
}
