import React from 'react';
import s from './ThankYouPageApp.scss';
import {useControllerProps} from '../ControllerContext';
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {THANK_YOU_PAGE_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';
import {PageContent as OldPageContent} from './PageContent.deprecated';
import {PageContent} from './PageContent';

export enum ThankYouPageAppDataHook {
  root = 'ThankYouPageAppDataHook.root',
  blank = 'ThankYouPageAppDataHook.blank',
}

function ThankYouPageApp() {
  const {ssrError, thankYouPageStore} = useControllerProps();
  if (ssrError) {
    return <div data-hook={ThankYouPageAppDataHook.blank} />;
  }

  return (
    <main data-hook={ThankYouPageAppDataHook.root} className={s.root}>
      {thankYouPageStore.shouldEnableNewTyp ? <PageContent /> : <OldPageContent />}
    </main>
  );
}

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(ThankYouPageApp, {
  dsn: THANK_YOU_PAGE_DSN,
  config: {environment: 'Native Component'},
});
