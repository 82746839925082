import React from 'react';
import {LocaleKeysProvider} from '../../LocaleKeysProvider';
import {ContinueShopping} from '../Components/ContinueShopping/ContinueShopping';
import {Header} from '../Components/Header/Header';
import {Layout} from '../Components/Layout/Layout';

export enum ThankYouPageContentDataHook {
  content = 'ThankYouPageAppDataHook.content',
}

export function PageContent() {
  return (
    <LocaleKeysProvider>
      <div data-hook={ThankYouPageContentDataHook.content}>
        <Header />
        <Layout />
        <ContinueShopping />
      </div>
    </LocaleKeysProvider>
  );
}
