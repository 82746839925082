import React from 'react';
import {Divider} from 'wix-ui-tpa/Divider';
import s from './Layout.scss';

export enum LayoutDataHook {
  root = 'LayoutDataHook.root',
  itemsSection = 'LayoutDataHook.itemsSection',
  totalsSection = 'LayoutDataHook.totalsSection',
  shippingSection = 'LayoutDataHook.shippingSection',
}

export const Layout = () => {
  return (
    <div className={s.root} data-hook={LayoutDataHook.root}>
      <Divider className={s.divider} />
      <div className={s.box}>
        <section data-hook={LayoutDataHook.itemsSection}></section>
        <Divider className={s.divider} />
        <section data-hook={LayoutDataHook.totalsSection}></section>
      </div>
      <div className={s.box}>
        <section data-hook={LayoutDataHook.shippingSection}></section>
      </div>
    </div>
  );
};
