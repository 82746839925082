/* tslint:disable */
export class LocaleKeys {
    constructor(private translate: Function) {
        //
    }

    public THANK_YOU_MSG = (options: { username: any }) => this.translate('THANK_YOU_MSG', options) /* THANKS FOR SHOPPING, {{username}}! */;
    public THANK_YOU_MSG_WITHOUT_NAME = () => this.translate('THANK_YOU_MSG_WITHOUT_NAME') /* THANKS FOR SHOPPING! */;
    public LIKE_YOUR_STYLE_TITLE = () => this.translate('LIKE_YOUR_STYLE_TITLE') /* HOPE TO SEE YOU AGAIN SOON. */;
    public SUMMARY_TITLE = () => this.translate('SUMMARY_TITLE') /* SUMMARY */;
    public ORDER_NUMBER_TITLE = () => this.translate('ORDER_NUMBER_TITLE') /* Your order number: */;
    public TOTAL_COST_TITLE = () => this.translate('TOTAL_COST_TITLE') /* Total cost: */;
    public SHIPPING_TO_TITLE = () => this.translate('SHIPPING_TO_TITLE') /* Shipping to: */;
    public SEND_EMAIL_TEXT = () => this.translate('SEND_EMAIL_TEXT') /*   */;
    public CONTINUE_SHOPPING_BUTTON = () => this.translate('CONTINUE_SHOPPING_BUTTON') /* Continue Shopping */;
    public SHIPPING_TO = (options: { city: any, state: any, zipCode: any, country: any }) => this.translate('SHIPPING_TO', options) /* {{city}}, {{state}} {{zipCode}}, {{country}} */;
    public thankYou = {
        TITLE: () => this.translate('thankYou.TITLE') /* Thank you for your order. */,
        TITLE_WITH_NAME: (options: { fullName: any }) => this.translate('thankYou.TITLE_WITH_NAME', options) /* Thank you for your order, {{fullName}}. */,
        SUBTITLE: () => this.translate('thankYou.SUBTITLE') /* You'll receive an email confirmation soon. */,
        ORDER_NUMBER_LABEL: () => this.translate('thankYou.ORDER_NUMBER_LABEL') /* Order No: */,
        TOTAL_LABEL: () => this.translate('thankYou.TOTAL_LABEL') /* Total: */,
        OFFLINE_PAYMENT_LABEL: () => this.translate('thankYou.OFFLINE_PAYMENT_LABEL') /* Offline Payment */,
        STORE_PICKUP_LABEL: () => this.translate('thankYou.STORE_PICKUP_LABEL') /* Pickup */,
        STORE_PICKUP_TIME: (options: { deliveryTime: any }) => this.translate('thankYou.STORE_PICKUP_TIME', options) /* Ready for pickup: {{deliveryTime}} */,
        SHIPPING_LABEL: () => this.translate('thankYou.SHIPPING_LABEL') /* Shipping to: */,
        DELIVERY_ADDRESS: (options: { addressLine: any, city: any, state: any, zipCode: any, country: any }) => this.translate('thankYou.DELIVERY_ADDRESS', options) /* {{addressLine}}
{{city}}, {{state}} {{zipCode}}
{{country}} */,
        DELIVERY_ADDRESS_FULL: (options: { fullName: any, company: any, addressLine: any, city: any, state: any, zipCode: any, country: any, phone: any }) => this.translate('thankYou.DELIVERY_ADDRESS_FULL', options) /* {{fullName}}
{{company}}
{{addressLine}}
{{city}}, {{state}} {{zipCode}}
{{country}}
{{phone}} */,
        DELIVERY_ADDRESS_FULL_INCLUDING_ADDRESS_LINE_2: (options: { fullName: any, company: any, addressLine: any, addressLine2: any, city: any, state: any, zipCode: any, country: any, phone: any }) => this.translate('thankYou.DELIVERY_ADDRESS_FULL_INCLUDING_ADDRESS_LINE_2', options) /* {{fullName}}
{{company}}
{{addressLine}}, {{addressLine2}}
{{city}}, {{state}} {{zipCode}}
{{country}}
{{phone}} */,
        DOWNLOAD_LINKS_LABEL: () => this.translate('thankYou.DOWNLOAD_LINKS_LABEL') /* Your Downloads: */,
        DOWNLOAD_LINK_LABEL: () => this.translate('thankYou.DOWNLOAD_LINK_LABEL') /* Download */,
        FOOTER_CONFIRMATION_TEXT: () => this.translate('thankYou.FOOTER_CONFIRMATION_TEXT') /* Keep an eye on your inbox - a confirmation email is on its way... */,
        FOOTER_DOWNLOADS_CONFIRMATION_TEXT: () => this.translate('thankYou.FOOTER_DOWNLOADS_CONFIRMATION_TEXT') /* Downloads available for 30 days. And keep an eye on your inbox - we have also sent them by email... */,
        FOOTER_LINK: () => this.translate('thankYou.FOOTER_LINK') /* Continue Shopping */,
        LOADING_TITLE: () => this.translate('thankYou.LOADING_TITLE') /* Processing your payment... */,
        PENDING_ORDER_MESSAGE: (options: { buyerMail: any }) => this.translate('thankYou.PENDING_ORDER_MESSAGE', options) /* Your order is being processed.
We will notify you once it has been approved by email to {{buyerMail}}. */,
        DOWNLOAD_LINKS_ERROR: () => this.translate('thankYou.DOWNLOAD_LINKS_ERROR') /* Download links are currently not available. */,
        DOWNLOAD_LINKS_ERROR_CONTINUED: () => this.translate('thankYou.DOWNLOAD_LINKS_ERROR_CONTINUED') /* You’ll receive an email confirmation soon with the download links to your products. */,
    };
    public THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR = (options: { frequencyUnitSingular: any }) => this.translate('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', options) /* 1 {{frequencyUnitSingular}} subscription */;
    public THANK_YOU_PAGE_SUBSCRIPTION_PLAN_LABEL = () => this.translate('THANK_YOU_PAGE_SUBSCRIPTION_PLAN_LABEL') /* Subscription: */;
    public THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL = (options: { numberOfFrequencyUnits: any, frequencyUnitSingular: any }) => this.translate('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', options) /* {{numberOfFrequencyUnits}} {{frequencyUnitSingular}} subscription */;
    public THANK_YOU_PAGE_SUBSCRIPTION_TITLE = (options: { fullName: any }) => this.translate('THANK_YOU_PAGE_SUBSCRIPTION_TITLE', options) /* Thank you for subscribing, {{fullName}}! */;
    public THANK_YOU_PAGE_PLAN_FREQUENCY_DESCRIPTION = (options: { price: any, frequencyUnitSingular: any }) => this.translate('THANK_YOU_PAGE_PLAN_FREQUENCY_DESCRIPTION', options) /* {{price}} / {{frequencyUnitSingular}} */;
    public THANK_YOU_PAGE_PLAN_MONTHS = () => this.translate('THANK_YOU_PAGE_PLAN_MONTHS') /* month */;
    public THANK_YOU_PAGE_PLAN_YEAR = () => this.translate('THANK_YOU_PAGE_PLAN_YEAR') /* year */;
    public THANK_YOU_PAGE_PLAN_WEEK = () => this.translate('THANK_YOU_PAGE_PLAN_WEEK') /* week */;
    public THANK_YOU_PAGE_PLAN_WEEKS = () => this.translate('THANK_YOU_PAGE_PLAN_WEEKS') /* week */;
    public THANK_YOU_PAGE_PLAN_MONTH = () => this.translate('THANK_YOU_PAGE_PLAN_MONTH') /* month */;
    public THANK_YOU_PAGE_PLAN_YEARS = () => this.translate('THANK_YOU_PAGE_PLAN_YEARS') /* year */;
    public THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW = () => this.translate('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW') /* Auto-renew until canceled */;
    public thankYouPage = {
        shipping: {
            label: () => this.translate('thankYouPage.shipping.label') /* Shipping */,
        },
        paymentMethod: {
            label: () => this.translate('thankYouPage.paymentMethod.label') /* Payment Method */,
        },
        subtitle: {
            orderNumber: (options: { orderNumber: any }) => this.translate('thankYouPage.subtitle.orderNumber', options) /* Order number: {{orderNumber}} */,
            orderConfirmation: () => this.translate('thankYouPage.subtitle.orderConfirmation') /* You'll receive an order confirmation soon.  */,
        },
        quantity: {
            label: () => this.translate('thankYouPage.quantity.label') /* Qty: */,
        },
        total: {
            label: () => this.translate('thankYouPage.total.label') /* Total */,
        },
        title: {
            withName: (options: { fullName: any }) => this.translate('thankYouPage.title.withName', options) /* Thank you, {{fullName}} */,
        },
        customerNote: {
            previewText: () => this.translate('thankYouPage.customerNote.previewText') /* Your customer's note will show here.  */,
            label: () => this.translate('thankYouPage.customerNote.label') /* Note */,
        },
        giftCard: {
            label: () => this.translate('thankYouPage.giftCard.label') /* Gift Card */,
        },
        tax: {
            label: () => this.translate('thankYouPage.tax.label') /* Tax */,
        },
        billingInfo: {
            label: () => this.translate('thankYouPage.billingInfo.label') /* Billing Info */,
        },
        back: {
            footerLink: () => this.translate('thankYouPage.back.footerLink') /* Back */,
        },
        subtotal: {
            label: () => this.translate('thankYouPage.subtotal.label') /* Subtotal */,
        },
        promoCode: {
            label: () => this.translate('thankYouPage.promoCode.label') /* Promo Code */,
        },
        moreServices: {
            footerLink: () => this.translate('thankYouPage.moreServices.footerLink') /* Check Out More Services */,
        },
        errorPage: {
            error: {
                title: () => this.translate('thankYouPage.errorPage.error.title') /* This page can't be accessed */,
                body: () => this.translate('thankYouPage.errorPage.error.body') /* Make sure you’re logged in to the right account, or check the URL and try again. */,
            },
        },
    };
}
/* tslint:enable */
